












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
	@Prop() private msg!: string;

	navigateToSection(section: string) {
		location.href = section;
	}
}
