









import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import About from "@/components/About.vue";
import Podcasts from "@/components/Podcasts.vue";

@Component({
	components: {
		Header,
		About,
		Podcasts
	}
})
export default class Home extends Vue {}
