











import { Component, Vue } from "vue-property-decorator";
import Home from "@/views/Home.vue";

@Component({
	components: {
		Home
	},
	data() {
		return {
			title: "Disregard That"
		};
	}
})
export default class App extends Vue {}
